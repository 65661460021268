// Import React and Axios
import React, {useState, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import copy from 'copy-to-clipboard';
import {toPng} from 'html-to-image';
import AutoCompleteInput from './sug'
import axios from 'axios'


// Define your component
const Subscribe = () => { // State to store the fetched data
  const getFuturenRecent7Days = () => {
    const dates = [];
    for (let i = 10; i >= 0; i--) {
      const date = new Date();
      date.setDate(date.getDate() + i);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const dateString = `${year}-${month}-${day}`;
      dates.unshift(dateString);
    }
    return dates;
  }


  const [email, setEmail] = useState('')
  const [selectDate, setSelectDate] = useState(getFuturenRecent7Days()[0])
  const [startTime, setStartTime] = useState('20:00')
  const [endTime, setEndTime] = useState('22:00')


  const getWeekdayFromDate = (dateString) => {
    const daysOfWeek = [
      '星期日',
      '星期一',
      '星期二',
      '星期三',
      '星期四',
      '星期五',
      '星期六'
    ];
    const date = new Date(dateString);
    const dayOfWeekIndex = date.getDay();
    return daysOfWeek[dayOfWeekIndex];
  }

  const generateTimeStrings = () => {
    let timeStrings = [];
    // let currentTime = new Date(startTime);

    let currentTime = new Date(); // May 2nd, 2024, 08:00 AM
    currentTime.setHours(0, 0, 0)

    let endTime = new Date(); // May 2nd, 2024, 06:00 PM
    endTime.setHours(23, 59, 59)

    // Loop until the current time exceeds the end time
    while (currentTime <= endTime) {
      let hour = currentTime.getHours();
      let minute = currentTime.getMinutes();

      // Format hour and minute strings with leading zeros if necessary
      let hourStr = hour < 10 ? '0' + hour : '' + hour;
      let minuteStr = minute < 10 ? '0' + minute : '' + minute;

      // Add the hour and minute string to the array
      timeStrings.push(hourStr + ':' + minuteStr);

      // Increment the current time by 30 minutes
      currentTime.setMinutes(currentTime.getMinutes() + 30);
    }

    return timeStrings;
  }

  const handleSub = (e) => {
    console.log(11111, selectDate, startTime, endTime, email);
    if (!email) {
      alert('请输入邮箱')
      return
    }
    const data = {
      "sub_date": selectDate,
      "sub_start_time": startTime,
      "sub_end_time": endTime,
      "sub_email": email
    }
    axios.post('http://47.106.82.158/sub/', data).then(alert('订阅成功，有空场将通过邮件提醒您～'))
  }


  return (
    <div style={
      { // textAlign: "center",
        display: "flex",
        // justifyContent: "center", /* Horizontal centering */
        alignItems: "center", /* Vertical centering */
        height: "100vh", /* Adjust as needed */
        flexDirection: "column"
      }
    }>
      <h2 class="CuzH2">订阅</h2>
      <p>场被订完的情况下使用 订阅后有人退场时会邮件通知</p>

      <p>
        <select onChange={
          e => setSelectDate(e.target.value)
        }>
          {
          getFuturenRecent7Days().map((item, idx) => <option value={item}>
            {item}
            ({
            getWeekdayFromDate(item)
          })</option>)
        } </select>
      </p>

      <p>
        <select onChange={
          e => setStartTime(e.target.value)
        }>
          {
          generateTimeStrings().map((item, idx) => <option selected={
            item === '20:00'
          }>
            {item}</option>)
        } </select>

        <span>-</span>
        <select onChange={
          e => setEndTime(e.target.value)
        }>
          {
          generateTimeStrings().map((item, idx) => <option selected={
            item === '22:00'
          }>
            {item}</option>)
        } </select>
      </p>

      <div>
        <input type="email" placeholder="请输入订阅邮箱"
          onChange={
            e => setEmail(e.target.value)
        }></input>
        <button onClick={handleSub}>订阅</button>
      </div>

      {/* <div>生成对阵表</div> */}
      <Link to="/"
        style={
          {
            position: "fixed",
            right: "1rem",
            top: "1rem"
          }
      }>查场</Link>
      <Link to="/z"
        style={
          {
            position: "fixed",
            right: "1rem",
            top: "3rem"
          }
      }>打转</Link>

    </div>
  );
}

export default Subscribe;
